export default [
    // {
    //   path: "/signup",
    //   name: "main-register",
    //   component: () => import("@/views/main/Register.vue"),
    // },
    {
      path: "/",
      name: "main-login",
      component: () => import("@/views/main/Login.vue"),
    },
]

  
  